<template>
  <base-modal title="Upload Image">
    <image-manager ref="assetImageManager" @upload="uploadImage"/>
  </base-modal>
</template>

<script>
import {mapState} from "vuex";

export default {

  data: () => ({
    uploading: false,
  }),

  computed:{
    ...mapState('asset', ['asset'])
  },

  methods: {
    uploadImage(blob) {
      this.uploading = true
      const payload = {
        assetId: this.$route.params.asset,
        image: blob
      }
      this.$store.dispatch('asset/uploadImage', payload).then(() => {
        this.uploading = false
        this.$refs.assetImageManager.completed()
        this.$toast.success('Image uploaded')
        this.$close(false)
      }).catch(() => {
        this.uploading = false
        this.$toast.error('whoops something went wrong, please try again.')
      })
    },
    clearImage(){
      this.uploading = true
      const payload = {
        assetId: this.$route.params.asset,
        image: null
      }
      this.$store.dispatch('asset/uploadImage', payload).then(() => {
        this.uploading = false
        this.$refs.assetImageManager.completed()
        this.$toast.success('Image uploaded')
        this.$close(false)
      }).catch(() => {
        this.uploading = false
        this.$toast.error('whoops something went wrong, please try again.')
      })
    }
  }

}
</script>
