<template>
  <base-modal title="Upload Asset Type Image">
    <image-manager ref="assetTypeImageManager" @upload="uploadImage"/>
  </base-modal>
</template>

<script>
import {mapState} from "vuex";
export default {
  data: () => ({
    uploading: false,
  }),

  computed:{
    ...mapState('assetType', ['assetType'])
  },

  methods: {
    uploadImage(blob) {
    this.uploading = true
      if (blob instanceof File || blob instanceof Blob) {
        let blobUrl = URL.createObjectURL(blob)
        this.$store.commit('assetType/imageUrl', blobUrl)
      }
      this.$store.commit('assetType/image', blob)
      this.$close(false)
    },
  }
}
</script>